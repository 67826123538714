import axios from "axios";

export default {
    setStateValue({ commit }, payload)
    {
        commit('setStateValue', payload);
    },
    async fetchExtraChargeList({ commit }, payload) {
        const response = await axios.post('/api/extra-charge/list', {
            supplier_alias: payload.supplier_alias,
            marketplace: payload.marketplace,
        });

        if (response.data.data.code == 'ok') {
            if (response.data.data.result.extra_charges.length) {
                commit('setExtraCharges', response.data.data.result.extra_charges);
            } else {
                commit('setExtraCharges', null);
            }
        }
    },
    async updateExtraCharge({ commit }, payload)
    {
        const response = await axios.post('/api/extra-charge/update', payload);

        return response.data.data;
    },
    async deleteExtraCharge({ commit }, payload)
    {
        return await axios.post('/api/extra-charge/delete', {id: payload});
    },
    setExtraChargeData({ commit, getters }, payload)
    {
        let extraCharge = getters.getExtraChargeByIndex(payload.index);

        if (extraCharge) {
            commit('setExtraChargeData', payload);
        }
    },
    async saveExtraCharge({ commit }, payload)
    {
        const response = await axios.post('/api/extra-charge/store', payload);

        console.log(response)
    },
    async rePrice({ commit })
    {
        const response = await axios.post('/api/extra-charge/re-price');

        if (response.data.data.result.success == true) {
            alert('Обновлено');
        }
    },
}