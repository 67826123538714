export default {
    name: "AppLayout",
    components: {

    },
    data: () => ({
        cards: ['Today', 'Yesterday'],
        drawer: null,
        links: [
            {
                title: 'Категории Полкар',
                link: '/catalog/categories/polcar'
            },
            {
                title: 'Поставщики',
                link: '/providers'
            },
            {
                title: 'Наценка',
                childs: [
                    {
                        title: 'Армтек',
                        link: '/extra_charge/armtek'
                    },
                    {
                        title: 'Forward',
                        link: '/extra_charge/forward'
                    },
                    {
                        title: 'Автотрейд',
                        link: '/extra_charge/autotrade'
                    },
                ],
            },
            {
                title: 'Товары',
                link: '/products'
            },
        ],
    }),
}
