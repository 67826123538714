export default {
    setStateValue(state, payload)
    {
        if (state.hasOwnProperty(payload.key)) {
            state[payload.key] = payload.value;
        }
    },
    setExtraCharges(state, payload) {
        state.extra_charges = payload;
    },
    setExtraChargeData(state, payload)
    {
        state.extra_charges[payload.index][payload.attribute_name] = payload.value;
    },
}
